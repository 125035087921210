import { CircularProgress, Typography } from '@material-ui/core';
import React, { Dispatch } from 'react';
import * as yup from 'yup';
import { useAuthContext } from '../components/authContext';
import DateTime from '../components/dateTime';
import FooterButton from '../components/footerButton';
import { InteractionBadge } from '../components/interactionBadge';
import Layout from '../components/layout';
import LoadingWrapper from '../components/loadingWrapper';
import SelectButtonGroup from '../components/selectButtonGroup';
import TallyTracker, { defaultDimensions } from '../components/tallyTracker';
import TextInput from '../components/textInput';
import { FormActions, FormState, useNewForm } from '../forms/form';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useConfig from '../hooks/useConfig';
import { Suggestions, useSuggestions } from '../hooks/useSuggestions';
import { ChildrenFamilySupport } from '../icons';
import { generateId } from '../services/entity';
import { Interaction, defaultSchema } from '../services/interactions';
import {
  FORM_TYPE_TITLE,
  FormConfiguration,
  FormType,
} from '../services/lists';
import { BaseProps } from '../typings/types';

export const schema = yup.object<Interaction>({
  ...defaultSchema,
  subtype: yup.string().required(),
  reasons: yup.mixed().required(),
  pickupLocation: yup.string().required(),
  dropOffLocation: yup.string().required(),
  clientAdvocacy: yup.string().required(),
  outcomes: yup.mixed().required(),
  comments: yup.string().required(),
});

interface PureNewSupportProps extends FormState {
  config?: FormConfiguration;
  dispatch: Dispatch<FormActions>;
  gettingConfig: boolean;
  loggingIn: boolean;
  suggestions: Suggestions;
}

export const PureNewSupport: React.FC<PureNewSupportProps> = ({
  config,
  gettingConfig,
  loggingIn,
  dispatch,
  submitting,
  submitted,
  suggestions,
  ...formState
}) => {
  const isValid = schema.isValidSync(formState);
  const {
    timeOccurred,
    subtype,
    reasons, // used to denote 'Client Type'
    tally,
    pickupLocation,
    dropOffLocation,
    clientAdvocacy,
    outcomes,
    comments,
    error,
  } = formState;

  const defaultConfig = config ? config : {};

  return (
    <Layout
      title={FORM_TYPE_TITLE.ChildrenFamilySupport}
      headerIcon={
        <InteractionBadge type={FormType.ChildrenFamilySupport}>
          <ChildrenFamilySupport />
        </InteractionBadge>
      }
    >
      <LoadingWrapper
        loading={gettingConfig || loggingIn}
        submitting={submitting}
        hasConfig={!!config}
        submitted={submitted}
        error={error}
      >
        <form
          data-testid="loaded"
          autoComplete="off"
          onSubmit={(e): void => {
            e.preventDefault();
            dispatch({
              type: 'SUBMIT',
            });
          }}
        >
          <DateTime
            label="Time"
            id="time-occurred"
            name="time-occurred"
            value={timeOccurred}
            onChange={(date): void => {
              dispatch({ type: 'CHANGE_TIME_OCCURRED', timeOccurred: date });
            }}
          />

          <SelectButtonGroup
            id="type"
            options={defaultConfig.subtypes}
            suggestOther={suggestions.suggestSubtype}
            label="Event type"
            value={subtype}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_SUBTYPE',
                subtype: value as string,
              });
            }}
          />

          <SelectButtonGroup
            id="clientType"
            label="Client type"
            value={reasons}
            options={defaultConfig.reasons}
            onChange={(value): void => {
              const reasons = typeof value === 'string' ? [value] : value;
              dispatch({
                type: 'CHANGE_REASON',
                reasons,
              });
            }}
          />

          <TallyTracker
            id="age-tally"
            label="Age / Gender"
            description="Enter the number of people you've observed per gender/age group."
            tally={tally}
            dimensions={{
              ...defaultDimensions,
              rows: defaultConfig.ageGroups,
            }}
            onChange={(newTally): void => {
              dispatch({ type: 'CHANGE_TALLY', tally: newTally });
            }}
          />

          <SelectButtonGroup
            id="pick-up-location"
            label="Pick up location"
            options={defaultConfig.pickupLocations}
            suggestOther={suggestions.suggestLocation}
            value={pickupLocation}
            onChange={(pickupLocation): void =>
              dispatch({
                type: 'CHANGE_PICKUP_LOCATION',
                pickupLocation: pickupLocation as string,
              })
            }
          />

          <SelectButtonGroup
            id="drop-off"
            label="Drop off location"
            options={defaultConfig.dropoffLocations}
            suggestOther={suggestions.suggestLocation}
            value={dropOffLocation}
            onChange={(dropOffLocation): void =>
              dispatch({
                type: 'CHANGE_DROP_LOCATION',
                dropOffLocation: dropOffLocation as string,
              })
            }
          />

          <SelectButtonGroup
            id="advocacy"
            options={defaultConfig.advocacyTypes}
            label="Agency / Advocacy"
            value={clientAdvocacy}
            suggestOther={suggestions.suggestClientAdvocacy}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_CLIENT_ADVOCACY',
                clientAdvocacy: value as string,
              });
            }}
          />

          <SelectButtonGroup
            id="outcome"
            label="Outcome"
            options={defaultConfig.outcomes}
            suggestOther={suggestions.suggestOutcome}
            value={outcomes}
            onChange={(value): void => {
              const outcomes = typeof value === 'string' ? [value] : value;
              dispatch({
                type: 'CHANGE_INTERACTION_OUTCOME',
                outcomes,
              });
            }}
          />

          <TextInput
            label="Comments"
            id="comments"
            name="comments"
            value={comments}
            multiline
            rows={3}
            onChange={(e): void => {
              dispatch({
                type: 'CHANGE_COMMENTS',
                comments: e.target.value,
              });
            }}
          />

          <FooterButton type="submit" disabled={!isValid || submitting}>
            {submitting ? (
              <>
                <CircularProgress />
                <Typography variant="srOnly">SUBMITTING</Typography>
              </>
            ) : (
              'SUBMIT'
            )}
          </FooterButton>
        </form>
      </LoadingWrapper>
    </Layout>
  );
};

export const NewSupport: React.FC<BaseProps> = ({ location }) => {
  const state = !!location.state ? location.state : {};
  const pathname = location.pathname;
  const { getUser, loggingIn } = useAuthContext();
  const { config, gettingConfig } = useConfig();
  useAuthRouteProtection(pathname);
  const childrenFamilySupportConfig =
    config?.items[FormType.ChildrenFamilySupport];
  const useNewInteractionResult = useNewForm({
    getUser,
    formType: FormType.ChildrenFamilySupport,
    existingId: !state?.id ? generateId() : state.id,
    config: childrenFamilySupportConfig,
  });
  const suggestions = useSuggestions(getUser);
  return (
    <PureNewSupport
      {...useNewInteractionResult}
      config={childrenFamilySupportConfig}
      gettingConfig={gettingConfig}
      loggingIn={loggingIn}
      suggestions={suggestions}
    />
  );
};
export default NewSupport;
